/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import {Stack} from '@servicetitan/design-system';
import {ResourceLink} from 'components';
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    a: "a",
    div: "div",
    p: "p"
  }, _provideComponents(), props.components);
  if (!Stack) _missingMdxReference("Stack", false);
  if (!Stack.Item) _missingMdxReference("Stack.Item", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.h2, {
    id: "adding-to-our-backlog",
    style: {
      position: "relative"
    }
  }, "Adding to our backlog", React.createElement(_components.a, {
    href: "#adding-to-our-backlog",
    "aria-label": "adding to our backlog permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "The easiest way to contribute to the design system is adding a bug or feature request to our backlog. Whether it's related to code, design assets, or documentation, we welcome requests and reports."), "\n", React.createElement(Stack, {
    spacing: 3,
    className: "w-100",
    wrap: "wrap"
  }, React.createElement(Stack.Item, {
    fill: true,
    style: {
      flexBasis: '300px'
    }
  }, React.createElement(ResourceLink, {
    title: "File a bug report",
    subtitle: "",
    url: "https://servicetitan.atlassian.net/secure/CreateIssue.jspa?issuetype=1&pid=11329",
    externalUrl: true,
    iconName: "bug_report"
  })), React.createElement(Stack.Item, {
    fill: true,
    style: {
      flexBasis: '300px'
    }
  }, React.createElement(ResourceLink, {
    title: "Add a Feature Request",
    subtitle: "",
    url: "https://servicetitan.atlassian.net/secure/CreateIssue.jspa?issuetype=11597&pid=11329",
    externalUrl: true,
    iconName: "lightbulb_outline"
  }))), "\n", React.createElement(_components.h2, {
    id: "for-designers",
    style: {
      position: "relative"
    }
  }, "For Designers", React.createElement(_components.a, {
    href: "#for-designers",
    "aria-label": "for designers permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "There are many ways for designers to contribute to Anvil, from adding work to the backlog, to creating entire components from research to implementation, and everything in-between. We’ve arranged a general process for many different ways to contribute."), "\n", React.createElement(Stack, {
    spacing: 3,
    className: "w-100",
    wrap: "wrap"
  }, React.createElement(Stack.Item, {
    fill: true,
    style: {
      flexBasis: '300px'
    }
  }, React.createElement(ResourceLink, {
    title: "Methods of Contributing",
    subtitle: "",
    url: "https://servicetitan.atlassian.net/l/c/Z5RHZHeL",
    externalUrl: true
  })), React.createElement(Stack.Item, {
    fill: true,
    style: {
      flexBasis: '300px'
    }
  }, React.createElement(ResourceLink, {
    title: "Explore Design Contribution Issues",
    subtitle: "",
    url: "https://servicetitan.atlassian.net/jira/software/c/projects/ANV/boards/767/backlog?selectedIssue=ANV-24&quickFilter=4430&issueLimit=100",
    externalUrl: true,
    customIcon: true,
    iconName: "Jira"
  }))), "\n", React.createElement(_components.h2, {
    id: "for-developers",
    style: {
      position: "relative"
    }
  }, "For Developers", React.createElement(_components.a, {
    href: "#for-developers",
    "aria-label": "for developers permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "Developer contribution resources are hosted within GitHub, where you can find resources on how to run and build the design system, and our code contribution process."), "\n", React.createElement(Stack, {
    spacing: 3,
    className: "w-100",
    wrap: "wrap"
  }, React.createElement(Stack.Item, {
    fill: true,
    style: {
      flexBasis: '300px'
    }
  }, React.createElement(ResourceLink, {
    title: "Codebase README",
    subtitle: "",
    url: "https://github.com/servicetitan/anvil#readme",
    externalUrl: true,
    iconName: "device_hub"
  })), React.createElement(Stack.Item, {
    fill: true,
    style: {
      flexBasis: '300px'
    }
  }, React.createElement(ResourceLink, {
    title: "Explore Code Contribution Issues",
    subtitle: "",
    url: "https://servicetitan.atlassian.net/jira/software/c/projects/ANV/boards/766/backlog?quickFilter=3281&issueLimit=100",
    externalUrl: true,
    customIcon: true,
    iconName: "Jira"
  }))));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
